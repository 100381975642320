import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Box, Paper, TableCell, Checkbox } from "@mui/material";
import DataTable, {
  IHeadCell,
  IDataTableRow,
} from "components/Common/DataTable";
import {
  selectZNSNotification,
  selectTotalCount,
  selectPageCount,
  loadZNSNotification,
} from "modules/notification/store/notificationSlice";
import NotificationService from "modules/notification/services/notificationService";
import Loading from "components/Common/Loading";
import { ConfirmModal } from "components/Common";

const headCells: IHeadCell[] = [
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "phoneNumber",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Code",
  },
  {
    id: "prizeName",
    numeric: false,
    disablePadding: false,
    label: "Prize",
  },
  {
    id: "lastSendError",
    numeric: false,
    disablePadding: false,
    label: "Error",
  },
];

function UserRow(props: IDataTableRow) {
  return (
    props.item && (
      <>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            checked={props.isItemSelected}
            inputProps={{
              "aria-labelledby": props.labelId,
            }}
          />
        </TableCell>
        <TableCell
          component="th"
          id={props.labelId}
          scope="row"
          sx={{ maxWidth: "200px", wordBreak: "break-word" }}
        >
          {props.item?.customerName}
        </TableCell>
        <TableCell>{props.item?.phoneNumber}</TableCell>
        <TableCell>{props.item?.code}</TableCell>
        <TableCell>{props.item?.prizeName}</TableCell>
        <TableCell>{props.item?.lastSendError}</TableCell>
      </>
    )
  );
}

export default function NotificationPage() {
  const dispatch = useAppDispatch();

  const ZNSNotification = useAppSelector(selectZNSNotification);
  const ZNSNotificationPageCount = useAppSelector(selectPageCount);
  const ZNSNotificationTotalCount = useAppSelector(selectTotalCount);

  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState("");
  const [paging, setPagingData] = useState({
    total: 0,
    pages: 1,
    page: 1,
    limit: 15,
  });

  const getZNSNotification = () => {
    dispatch(
      loadZNSNotification({
        status: -1,
        pageNumber: paging.page,
        pageSize: paging.limit,
      })
    );
  };

  const onPageChange = (page: number) => {
    setPagingData({
      ...paging,
      page,
    });
  };

  const confirmModal = () => {
    setOpenConfirmModal(false);
  };

  const resendNotificationMessages = (data: any) => {
    if (data) {
      NotificationService.resendNotificationMessages(data)
        .then(() => {
          setConfirmMessage("Resend successfully !!!");
          setOpenConfirmModal(true);
          getZNSNotification();
        })
        .catch((error) => {
          setConfirmMessage(
            error?.Message || "System error, please try again later"
          );
          setOpenConfirmModal(true);
        });
    } else {
      setConfirmMessage("System error, please try again later");
      setOpenConfirmModal(true);
    }
  };

  useEffect(() => {
    getZNSNotification();
  }, [paging.page]);

  useEffect(() => {
    const pageData = {
      ...paging,
      total: ZNSNotification?.totalCount || 0,
    };

    setPagingData({
      ...pageData,
      pages: Math.ceil(pageData.total / pageData.limit),
      page:
        ZNSNotificationPageCount === 0 && pageData.page > 1
          ? pageData.page - 1
          : pageData.page,
    });
  }, [ZNSNotificationTotalCount]);

  if (!ZNSNotification) {
    return <Loading />;
  }

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Paper sx={{ width: "100%", mb: 2 }}>
          <DataTable
            fromPage="notification"
            headCells={headCells}
            rows={ZNSNotification.results}
            paging={paging}
            name="notificationMessageId"
            title={"Notification"}
            showCheckBox
            onPageChange={onPageChange}
            onResendNotification={resendNotificationMessages}
          >
            <UserRow />
          </DataTable>
        </Paper>
      </Box>

      <ConfirmModal
        open={openConfirmModal}
        setOpen={setOpenConfirmModal}
        content={confirmMessage}
        onConfirm={confirmModal}
        backdropClick={true}
      />
    </>
  );
}

import NotificationPage from "modules/notification/pages/NotificationPage";
import NotificationHistoryPage from "./pages/NotificationHistoryPage";

const router = [
  {
    path: "notification/error",
    element: <NotificationPage />,
    loader: () => ({ title: "Notification Error List" }),
  },
  {
    path: "notification/history",
    element: <NotificationHistoryPage />,
    loader: () => ({ title: "Notification History" }),
  },
];

export default router;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { IHttpError } from "types/http";

export interface INotificationPayload {
  status?: number;
  pageNumber?: number;
  pageSize?: number;
}

export interface IMessage {
  notificationMessageId?: string;
  customerId?: string;
  customerName?: string;
  code?: string;
  prizeName?: string;
  prizeType?: number;
  template?: string;
  phoneNumber?: string;
  lastSendError?: string;
  sendAttemptCount?: number;
  lastSendAttemptDate?: string;
  sendDate?: string;
  status?: string;
}

export interface IMessages {
  results: IMessage[];
  totalCount: number;
}

export interface IState {
  loading?: boolean;
  data?: IMessages;
  error?: IHttpError;
}

const initialState: IState = {
  loading: false,
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    loadZNSNotification: (
      state,
      _action: PayloadAction<INotificationPayload>
    ) => {
      state.loading = true;
      state.data = undefined;
      state.error = undefined;
    },
    loadZNSNotificationSuccess: (state, action: PayloadAction<IMessages>) => {
      state.loading = false;
      state.data = action.payload;
    },
    loadZNSNotificationFailed: (state, action: PayloadAction<IHttpError>) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  loadZNSNotification,
  loadZNSNotificationSuccess,
  loadZNSNotificationFailed,
} = notificationSlice.actions;

export const selectZNSNotification = (state: RootState) =>
  state.notification?.data;
export const selectTotalCount = (state: RootState) =>
  state.notification?.data?.totalCount;
export const selectPageCount = (state: RootState) =>
  state.notification?.data?.results?.length;

export default notificationSlice.reducer;

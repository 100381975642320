import { call, put, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { IHttpError } from "types/http";
import NotificationService from "modules/notification/services/notificationService";

import {
  INotificationPayload,
  loadZNSNotification,
  loadZNSNotificationFailed,
  loadZNSNotificationSuccess,
} from "./notificationSlice";

function* handleLoadZNSNotificationHistory(
  action: PayloadAction<INotificationPayload>
): any {
  try {
    const messages = yield call(
      NotificationService.getErrorsZNSNotification,
      action.payload
    );
    yield put(loadZNSNotificationSuccess(messages));
  } catch (error) {
    yield put(loadZNSNotificationFailed(error as IHttpError));
  }
}

export default function* notificationSaga() {
  yield takeLatest(
    loadZNSNotification.toString(),
    handleLoadZNSNotificationHistory
  );
}

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "app/hooks";
import { Box, Paper, TableCell, Checkbox } from "@mui/material";
import DataTable, {
  IHeadCell,
  IDataTableRow,
} from "components/Common/DataTable";
import {
  loadZNSNotification,
  selectZNSNotification,
  selectTotalCount,
  selectPageCount,
} from "modules/notification/store/notificationSlice";
import NotificationService from "modules/notification/services/notificationService";
import Loading from "components/Common/Loading";
import { ConfirmModal } from "components/Common";
import moment from "moment";

const headCells: IHeadCell[] = [
  {
    id: "customerName",
    numeric: false,
    disablePadding: false,
    label: "Customer Name",
  },
  {
    id: "phoneNumber",
    numeric: false,
    disablePadding: false,
    label: "Phone Number",
  },
  {
    id: "code",
    numeric: false,
    disablePadding: false,
    label: "Code",
  },
  {
    id: "template",
    numeric: false,
    disablePadding: false,
    label: "Template Id",
  },
  {
    id: "prizeName",
    numeric: false,
    disablePadding: false,
    label: "Prize",
  },
  {
    id: "sendDate",
    numeric: false,
    disablePadding: false,
    label: "Send Date",
  },
];

function UserRow(props: IDataTableRow) {
  return (
    props.item && (
      <>
        <TableCell
          component="th"
          id={props.labelId}
          scope="row"
          sx={{ maxWidth: "200px", wordBreak: "break-word" }}
        >
          {props.item?.customerName}
        </TableCell>
        <TableCell>{props.item?.phoneNumber}</TableCell>
        <TableCell>{props.item?.code}</TableCell>
        <TableCell>{props.item?.template}</TableCell>
        <TableCell>{props.item?.prizeName}</TableCell>
        <TableCell>
          {moment(props.item?.sendDate).format("YYYY/MM/DD HH:mm:ss z")}
        </TableCell>
      </>
    )
  );
}

export default function NotificationHistoryPage() {
  const dispatch = useAppDispatch();

  const ZNSNotification = useAppSelector(selectZNSNotification);
  const ZNSNotificationPageCount = useAppSelector(selectPageCount);
  const ZNSNotificationTotalCount = useAppSelector(selectTotalCount);

  const [paging, setPagingData] = useState({
    total: 0,
    pages: 1,
    page: 1,
    limit: 15,
  });

  const getZNSNotification = () => {
    dispatch(
      loadZNSNotification({
        status: 1,
        pageNumber: paging.page,
        pageSize: paging.limit,
      })
    );
  };

  const onPageChange = (page: number) => {
    setPagingData({
      ...paging,
      page,
    });
  };

  useEffect(() => {
    getZNSNotification();
  }, [paging.page]);

  useEffect(() => {
    const pageData = {
      ...paging,
      total: ZNSNotification?.totalCount || 0,
    };

    setPagingData({
      ...pageData,
      pages: Math.ceil(pageData.total / pageData.limit),
      page:
        ZNSNotificationPageCount === 0 && pageData.page > 1
          ? pageData.page - 1
          : pageData.page,
    });
  }, [ZNSNotificationTotalCount]);

  if (!ZNSNotification) {
    return <Loading />;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <DataTable
          fromPage="notification"
          headCells={headCells}
          rows={ZNSNotification.results}
          paging={paging}
          name="notificationMessageId"
          title={"Notification"}
          onPageChange={onPageChange}
        >
          <UserRow />
        </DataTable>
      </Paper>
    </Box>
  );
}
